:root {
  /* body1 */
  --font-family-body1: "Space Grotesk", sans-serif;
  --font-size-body1: 18px;
  --font-weight-body1: 400;

  /* body2 */
  --font-family-body2: "Space Grotesk", sans-serif;
  --font-size-body2: 16px;
  --font-weight-body2: 400;

  /* buttonText */
  --font-family-buttonText: "Space Grotesk", sans-serif;
  --font-size-buttonText: 20px;
  --font-weight-buttonText: 500;
  --line-height-buttonText: normal;

  /* header1 */
  --font-family-header1: "Space Grotesk", sans-serif;
  --font-size-header1: 32px;
  --font-weight-header1: 700;

  /* header3 */
  --font-family-header3: "Space Grotesk", sans-serif;
  --font-size-header3: 16px;
  --font-weight-header3: 700;

  /* questionText */
  --font-family-questionText: "Space Grotesk", sans-serif;
  --font-size-questionText: 24px;
  --font-weight-questionText: 500;
}

@media only screen and (max-width: 768px) {
  :root {
    /* body1 */
    --font-size-body1: 16px;

    /* body2 */
    --font-size-body2: 14px;

    /* buttonText */
    --font-size-buttonText: 18px;

    /* header1 */
    --font-size-header1: 26px;

    /* questionText */
    --font-size-questionText: 20px;
  }
}
