.error {
  margin-top: -32px;
}

.metaDescriptionInput {
  width: 547px !important;
}

.metaNameInput {
  text-align: center;
  width: 397px !important;
}

.royalties {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  row-gap: 24px;
}

.royaltiesLabels {
  align-self: stretch;
  column-gap: 24px;
  display: flex;
}

.royaltiesLabelsLeft {
  /* Must watch walletAddress width */
  width: 423px;
}

.royaltiesRow {
  column-gap: 24px;
  display: flex;
}

.walletAddress {
  width: 423px !important;
}

.walletPercent {
  width: 100px !important;
}