.containerInner {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
}

.content {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 42px;
  row-gap: 64px;
}

.loading {
  margin-top: 42px;
}

.noProject {
  margin-top: 55px;
}