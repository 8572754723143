@keyframes fade-in {
  to {
    opacity: 1;
  }
}

.containerInner {
  align-items: center;
  background-color: var(--color-purple);
  column-gap: 16px;
  display: flex;
  justify-content: center;
  opacity: 0;

  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-name: fade-in;
}

.containerOuter {
  background-color: var(--color-purple);
  padding: 12px 0px;
}

.containerOuter:hover {
  cursor: pointer;
}

.desktopCopy {
  display: block;
}

.mobileCopy {
  display: none;
}

.underline {
  text-decoration: underline;
}

@media only screen and (max-width: 640px) {
  .desktopCopy {
    display: none;
  }

  .mobileCopy {
    display: block;
  }
}