.buttons {
  align-items: center;
  column-gap: 64px;
  display: flex;
  justify-content: center;
}

.containerInner {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 42px 0px;
  row-gap: 16px;
}

.icons {
  align-items: center;
  column-gap: 32px;
  display: flex;
  margin-top: 16px;
}

.logo {
  width: 254px;
}

.mycoverseLink {
  color: var(--color-white);
  text-decoration: none;
}

@media only screen and (max-width: 640px) {
  .buttons {
    flex-direction: column;
    row-gap: 12px;
  }
}