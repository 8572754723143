.containerInner {
  margin-top: 48px;
  padding-bottom: 100px;
}

.folderStructureDescription {
  margin-top: 40px;
  max-width: 700px;
}

.folderStructureImageDesktop {
  display: block;
  max-width: 100%;
}

.folderStructureImageMobile {
  display: none;
}

@media only screen and (max-width: 868px) {
  .containerInner {
    margin-top: 0px;
    padding-bottom: 48px;
  }
}

@media only screen and (max-width: 640px) {
  .folderStructureImageDesktop {
    display: none;
  }

  .folderStructureImageMobile {
    display: block;
  }
}