.button {
  padding: 8px 16px !important;
}

.buttons {
  column-gap: 32px;
  display: flex;
  margin-top: 24px;
}

.input {
  text-align: center;
}

.inputs {
  align-items: center;
  column-gap: 36px;
  display: grid;
  grid-template-columns: repeat(3, auto);
  margin-top: 36px;
  row-gap: 24px;
}

.percent {
  width: 30px;
}