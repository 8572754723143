.buttons {
  align-items: center;
  column-gap: 64px;
  display: flex;
  justify-content: center;
}

.containerInner {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 60px 0px;
  row-gap: 36px;
}

.icons {
  align-items: center;
  column-gap: 60px;
  display: flex;
}

.logo {
  width: 384px;
}

.mycoverse {
  margin-top: -16px;
}

.mycoverseLink {
  color: var(--color-white);
  text-decoration: none;
}

@media only screen and (max-width: 640px) {
  .buttons {
    flex-direction: column;
    row-gap: 24px;
  }

  .containerInner {
    padding: 40px 0px;
    row-gap: 32px;
  }

  .logo {
    width: 250px;
  }
}