.circle {
  border-radius: 50%;
  height: 12px;
  width: 12px;
}

.container {
  align-items: center;
  column-gap: 24px;
  display: flex;
  margin-top: 24px;
  position: relative;
}

.item {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  row-gap: 12px;
  width: 140px;
  z-index: 1;
}

.item:hover {
  cursor: pointer;
}

.line {
  border-bottom: 1px dashed var(--color-white);
  bottom: 6px;
  left: 70px;
  position: absolute;
  width: 83%;
}