body {
  background-color: white;
  height: 100%;
  margin: 0px;
}

html {
  height: 100%;
}

#root {
  height: 100%;
}

.buttonPlain {
  border-style: none;
  outline: none;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
}

.buttonPlain:hover {
  cursor: pointer;
}

/* See https://stackoverflow.com/questions/1833734/display-inline-block-extra-margin/8262649#8262649 */
.hideText {
  color: transparent;
  display: block;
  font-size: 0;
  line-height: 0;
}

.gradientBg {
  background: radial-gradient(82.95% 212.36% at 0% 90.67%, #FFFFFF 0%, #FFB4B4 34.74%, #86AEF9 100%), #86AEF9;
}

.pixelArtImage {
  /* From https://stackoverflow.com/questions/14068103/disable-antialising-when-scaling-images */
  image-rendering: optimizeSpeed; /* STOP SMOOTHING, GIVE ME SPEED  */
  image-rendering: -moz-crisp-edges; /* Firefox                        */
  image-rendering: -o-crisp-edges; /* Opera                          */
  image-rendering: -webkit-optimize-contrast; /* Chrome (and eventually Safari) */
  image-rendering: pixelated; /* Chrome */
  image-rendering: optimize-contrast; /* CSS3 Proposed                  */
  -ms-interpolation-mode: nearest-neighbor; /* IE8+  */
}