.containerInner {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  padding-bottom: 80px;
}

.ctaButton {
  margin-top: 42px;
  padding: 20px 48px !important;

  font-size: 32px;
}

.description {
  margin-top: 40px;
  max-width: 700px;
}

.explodedAndFinal {
  margin-top: 48px;
}

.headerText {
  color: var(--color-white);
  margin: 0px;
  max-width: 1000px;
  text-align: center;

  font-family: "Praline";
  font-size: 140px;
}

.squiggly {
  margin-top: 24px;
}

@media only screen and (max-width: 2000px) {
  .containerInner {
    margin-top: 42px;
  }

  .description {
    margin-top: 32px;
  }

  .explodedAndFinal {
    margin-top: 42px;
  }

  .headerText {
    font-size: 100px;
    max-width: 800px;
  }
}

@media only screen and (max-width: 1024px) {
  .headerText {
    font-size: 90px;
    max-width: 700px;
  }
}

@media only screen and (max-width: 868px) {
  .containerInner {
    margin-top: 0px;
  }

  .headerText {
    max-width: 600px;
  }
}

@media only screen and (max-width: 768px) {
  .containerInner {
    margin-top: 0px;
    padding-bottom: 60px;
  }

  .ctaButton {
    margin-top: 36px;
    padding: 20px 32px !important;

    font-size: 24px;
  }

  .headerText {
    font-size: 80px;
    max-width: 500px;
  }

  .squiggly {
    max-width: 400px;
  }
}

@media only screen and (max-width: 640px) {

  .headerText {
    font-size: 60px;
  }

  .squiggly {
    max-width: 400px;
    width: 100%;
  }
}