.button {
  align-items: center;
  border-radius: 12px;
  border-style: none;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  padding: 16px;
  outline: none;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

.button:disabled,
.disabled {
  cursor: default;
  opacity: .48;
  pointer-events: none;
}

.buttonAutoWidth {
  padding: 12px 24px;
}

.button:enabled:hover {
  cursor: pointer;
}

.icon {
  /* Fixes https://github.com/vercel/next.js/issues/21914 */
  font-size: 0px;
  line-height: 0px;
}

.iconLeft {
  margin-right: 10px;
}

.iconRight {
  margin-left: 10px;
}

.label:hover {
  cursor: pointer;
}

.linkContent:hover {
  cursor: pointer;
}

/*
 * Themes
 */

/* Purple theme */

.purpleTheme,
.purpleThemeLink {
  background-color: var(--color-purple);
  color: var(--color-white);
}

.purpleTheme:enabled:active,
.purpleThemeLink:active {
  background-color: var(--color-purpleHover);
}

@media (hover: hover) {
  .purpleTheme:enabled:hover,
  .purpleThemeLink:hover {
    background-color: var(--color-purpleHover);
  }

  .label.purpleTheme:hover {
    background-color: var(--color-purpleHover);
  }
}