.containerInner {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 64px 0px 80px;
  row-gap: 32px;
}

.description {
  max-width: 850px;
}