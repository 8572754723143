.buttonInner {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.container {
  position: relative;
}

.inputContainer {
  align-items: center;
  column-gap: 16px;
  display: flex;
}

.permaPlaceholder {
  color: var(--color-ghost);
  /* Keep consistent with .textInput horizontal padding */
  left: 16px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
