.body1Font {
  font-family: var(--font-family-body1);
  font-size: var(--font-size-body1);
  font-weight: var(--font-weight-body1);
  letter-spacing: var(--letter-spacing-body1);
  line-height: var(--line-height-body1);
}

.body1MediumFont {
  font-family: var(--font-family-body1);
  font-size: var(--font-size-body1);
  font-weight: 500;
  letter-spacing: var(--letter-spacing-body1);
  line-height: var(--line-height-body1);
}

.body1SemiBoldFont {
  font-family: var(--font-family-body1);
  font-size: var(--font-size-body1);
  font-weight: 600;
  letter-spacing: var(--letter-spacing-body1);
  line-height: var(--line-height-body1);
}

.body1BoldFont {
  font-family: var(--font-family-body1);
  font-size: var(--font-size-body1);
  font-weight: 700;
  letter-spacing: var(--letter-spacing-body1);
  line-height: var(--line-height-body1);
}

.body2Font {
  font-family: var(--font-family-body2);
  font-size: var(--font-size-body2);
  font-weight: var(--font-weight-body2);
  letter-spacing: var(--letter-spacing-body2);
  line-height: var(--line-height-body2);
}

.body2MediumFont {
  font-family: var(--font-family-body2);
  font-size: var(--font-size-body2);
  font-weight: 500;
  letter-spacing: var(--letter-spacing-body2);
  line-height: var(--line-height-body2);
}

.body2SemiBoldFont {
  font-family: var(--font-family-body2);
  font-size: var(--font-size-body2);
  font-weight: 600;
  letter-spacing: var(--letter-spacing-body2);
  line-height: var(--line-height-body2);
}

.body2BoldFont {
  font-family: var(--font-family-body2);
  font-size: var(--font-size-body2);
  font-weight: 700;
  letter-spacing: var(--letter-spacing-body2);
  line-height: var(--line-height-body2);
}

.buttonTextFont {
  font-family: var(--font-family-buttonText);
  font-size: var(--font-size-buttonText);
  font-weight: var(--font-weight-buttonText);
  letter-spacing: var(--letter-spacing-buttonText);
  line-height: var(--line-height-buttonText);
}

.header1Font {
  font-family: var(--font-family-header1);
  font-size: var(--font-size-header1);
  font-weight: var(--font-weight-header1);
  letter-spacing: var(--letter-spacing-header1);
  line-height: var(--line-height-header1);
}

.header3Font {
  font-family: var(--font-family-header3);
  font-size: var(--font-size-header3);
  font-weight: var(--font-weight-header3);
  letter-spacing: var(--letter-spacing-header3);
  line-height: var(--line-height-header3);
}

.questionTextFont {
  font-family: var(--font-family-questionText);
  font-size: var(--font-size-questionText);
  font-weight: var(--font-weight-questionText);
  letter-spacing: var(--letter-spacing-questionText);
  line-height: var(--line-height-questionText);
}

