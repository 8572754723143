.body {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0px 32px 32px;
  width: 600px;
}

.ctaButton {
  height: 58px;
  margin-top: 32px;
  padding: 16px 24px;
  width: 190px;
}

.secondaryButton {
  margin-top: 24px;
}

@media only screen and (max-width: 768px) {
  .body {
    width: 500px;
  }
}

@media only screen and (max-width: 640px) {
  .body {
    padding: 0px 16px 32px;
    width: 300px;
  }
}