.addFolder {
  align-items: center;
  background: var(--color-transparentPartial);
  backdrop-filter: blur(40px);
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  padding: 64px 100px;
}

.addFolderButton {
  margin-top: 24px;
}

.addFolderDescription {
  margin-top: 36px;
  max-width: 452px;
  text-align: center;
}

.addFolderImage {
  height: 100px;
  width: 123px;
}

.addFolderInput {
  display: none;
}

.containerInner {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  padding-bottom: 80px;
}

.description {
  margin-top: 24px;
  max-width: 620px;
}

.errorMessage {
  margin-top: 24px;
  max-width: 452px;
}

.folderGrid {
  margin-top: 60px;
}

.folderImage {
  width: 99px;
}

.folderItem {
  align-items: center;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.moreInfoButton {
  margin-top: 16px;
}

.shuffleButton {
  height: 50px;
  margin-top: 48px;
  width: 118px;
}

.textInput {
  margin-top: 32px;
  width: 300px;
}