.body {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0px 64px 48px;
}

.description {
  max-width: 800px;
}

.teamMemberProfile {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.teamMemberProfileDescription {
  margin-top: 8px;
  white-space: nowrap;
}

.teamMemberProfileImage {
  border-radius: 50%;
  height: 210px;
  object-fit: cover;
  width: 210px;
}

.teamMemberProfileTwitter {
  margin-top: 16px;
}

.teamMemberProfiles {
  column-gap: 70px;
  display: flex;
  margin-top: 36px;
}

@media only screen and (max-width: 868px) {
  .imageContainer {
    height: 215px;
    width: 215px;
  }
}

@media only screen and (max-width: 640px) {
  .body {
    padding: 0px 32px 42px;
  }

  .teamMemberProfile {
    text-align: center;
  }

  .teamMemberProfileImage {
    height: 110px;
    width: 110px;
  }

  .teamMemberProfiles {
    align-items: center;
    column-gap: 32px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    /* align-items: center;
    flex-direction: column;
    row-gap: 24px; */
  }
}