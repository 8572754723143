.toggle {
  align-items: center;
  border: 2px solid var(--color-white);
  border-radius: 12px;
  box-sizing: border-box;
  display: grid;
  height: 58px;
  justify-content: space-around;
  position: relative;
  width: 100%;
}

.toggleButton {
  position: relative;
  z-index: 1;
}

.toggleHighlight {
  background-color: var(--color-purple);
  border-radius: 8px;
  height: 42px;
  position: absolute;
  top: 7px;
  transition: left 0.5s;
  z-index: 0;
}
