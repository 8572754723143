.body {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0px 64px 48px;
}

.button {
  margin-top: 32px;
}

.description {
  max-width: 500px;
}

.textArea {
  border-radius: 12px;
  box-shadow: 0px 0px 30px rgba(0,0,0,0.15);
  margin-top: 24px;
  width: 400px;
}