:root {
  --color-backgroundOverlay: rgba(0, 0, 0, 0.3);
  --color-dimmer: rgba(0, 0, 0, 0.36);
  --color-error: #e92525;
  --color-green: #0d9350;

  --color-ghost: #c6c6c6;
  --color-navy: #172A70;
  --color-navyHover: #080561;
  --color-pink: #FFCBCB;
  --color-purple: #506CD0;
  --color-purpleHover: #4460c5;
  --color-purplePastel: #A1B0EA;
  --color-transparentPartial: rgba(255, 255, 255, 0.8);
  --color-white: #FFFFF3;
}