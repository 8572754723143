.chooseFolder {
  align-items: center;
  background: var(--color-transparentPartial);
  backdrop-filter: blur(40px);
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  padding: 64px 100px;
}

.chooseFolderButton {
  margin-top: 24px;
}

.chooseFolderDescription {
  margin-top: 36px;
  max-width: 500px;
  text-align: center;
}

.chooseFolderImage {
  height: 100px;
  width: 123px;
}

.chooseFolderInput {
  display: none;
}

.containerInner {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 55px;
}

.projectGrid {
  margin-bottom: 64px;
  margin-top: 64px;
}