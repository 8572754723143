.body {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0px 64px 48px;
}

.button {
  margin-top: 24px;
}

.description {
  max-width: 500px;
}

@media only screen and (max-width: 640px) {
  .body {
    padding: 0px 32px 42px;
  }
}
