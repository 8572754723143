.button {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.58) !important;
  border-radius: 100px;
  color: var(--color-white);
  column-gap: 10px;
  display: flex;
  height: 40px;
  padding: 12px 16px;
}

.buttons {
  align-items: center;
  display: none;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  row-gap: 16px;
  width: 100%;
}

.imageContainer:hover .buttons {
  display: flex;
}

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.description {
  max-width: 800px;
}

.downloadButton {
  height: 50px;
  margin-top: 24px;
  width: 333px;
}

.grid {
  column-gap: 24px;
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  margin-top: 50px;
  row-gap: 24px;
}

.image {
  height: 160px;
  width: 160px;
}

.imageContainer {
  height: 160px;
  width: 160px;
  position: relative;
}

.loadMoreButton {
  margin-top: 36px;
}

.note {
  margin-top: 12px;
}

.progress {
  margin-top: 12px;
}

.title {
  max-width: 500px;
}

@media only screen and (max-width: 1500px) {
  .grid {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}

@media only screen and (max-width: 1300px) {
  .grid {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

@media only screen and (max-width: 1100px) {
  .grid {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media only screen and (max-width: 900px) {
  .grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}