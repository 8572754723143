.button {
  width: 143px;
}

.buttons {
  column-gap: 32px;
  display: flex;
  margin-top: 32px;
}

.children {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 36px;
}

.container {
  align-items: center;
  background: var(--color-transparentPartial);
  backdrop-filter: blur(40px);
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  padding: 48px 64px;
}

.description {
  margin-top: 18px;
  max-width: 800px;
}

@media only screen and (max-width: 768px) {
  .container {
    padding: 42px 48px;
  }
}

@media only screen and (max-width: 640px) {
  .button {
    width: 135px;
  }

  .buttons {
    column-gap: 16px;
  }

  .container {
    padding: 32px 32px;
  }
}