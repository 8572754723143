.backgroundColorBackgroundOverlay {
  background-color: var(--color-backgroundOverlay);
}

.backgroundColorDimmer {
  background-color: var(--color-dimmer);
}

.backgroundColorError {
  background-color: var(--color-error);
}

.backgroundColorGreen {
  background-color: var(--color-green);
}

.backgroundColorGhost {
  background-color: var(--color-ghost);
}

.backgroundColorNavy {
  background-color: var(--color-navy);
}

.backgroundColorNavyHover {
  background-color: var(--color-navyHover);
}

.backgroundColorPink {
  background-color: var(--color-pink);
}

.backgroundColorPurple {
  background-color: var(--color-purple);
}

.backgroundColorPurpleHover {
  background-color: var(--color-purpleHover);
}

.backgroundColorPurplePastel {
  background-color: var(--color-purplePastel);
}

.backgroundColorTransparentPartial {
  background-color: var(--color-transparentPartial);
}

.backgroundColorWhite {
  background-color: var(--color-white);
}

