.desktopContainer {
  display: block;
  height: 100%;
}

.mobileContainer {
  display: none;
  height: 100%;
}

@media only screen and (max-width: 768px) {
  .desktopContainer {
    display: none;
  }

  .mobileContainer {
    display: block;
  }
}

