.item {
  align-items: center;
  column-gap: 24px;
  display: flex;
  margin-top: 24px;
}

.itemRight {
  align-items: center;
  background-color: var(--color-white);
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  padding: 19px 28px;
  width: 603px;  
}

.list {
  display: flex;
  flex-direction: column;
  margin-top: -12px;
}