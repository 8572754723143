.bigToggleBlockchain {
  width: 304px;
}

.bigToggleMetadata {
  width: 207px;
}

.changeFolderInput {
  display: none;
}

.error {
  margin-top: -32px;
}

.folderImage {
  width: 99px;
}

.folderInput {
  align-items: center;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}

.folderInputText {
  align-items: center;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.numImagesInput {
  text-align: center;
  width: 213px !important;
}

.projectNameInput {
  text-align: center;
  width: 350px !important;
}