.container {
  align-items: center;
  column-gap: 42px;
  display: flex;
}

.exploded {
  width: 400px;
}

.final {
  border: 3px solid var(--color-white);
  border-radius: 12px;
  height: 330px;
  position: relative;
  width: 330px;
}

.longArrowDesktop {
  display: block;
}

.longArrowMobile {
  display: none;
}

@media only screen and (max-width: 868px) {
  .desktopContainer {
    display: none;
  }

  .mobileContainer {
    display: block;
  }
}


@media only screen and (max-width: 1124px) {
  .container {
    column-gap: 28px;
  }

  .exploded {
    width: 320px;
  }

  .final {
    height: 250px;
    width: 250px;
  }
}

@media only screen and (max-width: 950px) {
  .exploded {
    width: 290px;
  }

  .final {
    height: 220px;
    width: 220px;
  }

  .longArrowDesktop {
    width: 100px;
  }
}

@media only screen and (max-width: 820px) {
  .exploded {
    width: 250px;
  }

  .final {
    height: 180px;
    width: 180px;
  }

  .longArrowDesktop {
    width: 80px;
  }
}

@media only screen and (max-width: 680px) {
  .container {
    flex-direction: column;
    row-gap: 24px;
  }

  .longArrowDesktop {
    display: none;
  }

  .longArrowMobile {
    display: block;
  }
}