.containerInner {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 30px 0px;
  row-gap: 16px;
}

.containerOuter {
  border-bottom: 4px solid var(--color-white);
  border-top: 4px solid var(--color-white);
}

.image {
  height: 300px;
  width: 300px;
}

@media only screen and (max-width: 640px) {
  .image {
    height: 180px;
    width: 180px;
  }
}