.folderGrid {
  margin-top: 36px;
}

.folderImage {
  width: 99px;
}

.item {
  align-items: center;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}