.button {
  align-items: center;
  background-color: transparent;
  border-style: none;
  outline: none;
  padding: 0;
  text-decoration: none;
}

.button:hover {
  cursor: pointer;
  text-decoration: underline;
}

.iconContainer {
  margin-right: 12px;
}

/* Themes */

/* Navy */
.buttonNavy,
.buttonNavyActive {
  color: var(--color-navy);
}

/* Purple */
.buttonPurple,
.buttonPurpleActive {
  color: var(--color-purple);
}

/* White */
.buttonWhite,
.buttonWhiteActive {
  color: var(--color-white);
}