.colorBackgroundOverlay {
  color: var(--color-backgroundOverlay);
}

.colorDimmer {
  color: var(--color-dimmer);
}

.colorError {
  color: var(--color-error);
}

.colorGreen {
  color: var(--color-green);
}

.colorGhost {
  color: var(--color-ghost);
}

.colorNavy {
  color: var(--color-navy);
}

.colorNavyHover {
  color: var(--color-navyHover);
}

.colorPink {
  color: var(--color-pink);
}

.colorPurple {
  color: var(--color-purple);
}

.colorPurpleHover {
  color: var(--color-purpleHover);
}

.colorPurplePastel {
  color: var(--color-purplePastel);
}

.colorTransparentPartial {
  color: var(--color-transparentPartial);
}

.colorWhite {
  color: var(--color-white);
}

