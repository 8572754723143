.container {
  align-items: center;
  column-gap: 60px;
  display: flex;
}

.descriptions {
  display: flex;
  flex-direction: column;
  row-gap: 29px;
}

.exploded {
  width: 400px;
}

.item {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
}

@media only screen and (max-width: 768px) {
  .container {
    column-gap: 30px;
  }
}

@media only screen and (max-width: 640px) {
  .container {
    align-items: center;
    flex-direction: column;
    row-gap: 36px;
  }

  .exploded {
    max-width: 400px;
    width: 90%;
  }
}